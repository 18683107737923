import React from "react";
import "./style.css";

const TableFish = (props) => {
  return (
    <main>
      {props.fishMass.map((elem, index) => {
        let fish = elem.fish.map((fish) => {
          return (
            <>
              <tr className="fish-table__tr">
                <td>{fish.name}</td>
                <td className="fish-table__count">{fish.count}</td>
              </tr>
            </>
          );
        });
        return (
          <table className="fish-table" key={index}>
            <thead className="fish-table__head">
              <tr>
                <th>{elem.title}</th>
              </tr>
              <tr>
                <th className="fish-table__subtitle">{elem.subtitle}</th>
              </tr>
            </thead>
            <tbody>{fish}</tbody>
          </table>
        );
      })}
    </main>
  );
};
export default TableFish;
