import React from 'react';
import { useState } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import './header-container.css';
import { NavbarBurger } from '../navbarBurger/navbarBurger';

import logo from '../../assets/images/Logo.png';
import { ReactComponent as User } from '../../assets/images/user.svg';
import { ReactComponent as Basket } from '../../assets/images/basket.svg';
import { calcTotalPrice } from '../utils';
import { CartMenu } from '../cart-menu';
import { ItemsInCart } from '../items-in-cart';
import Modal from '../../Modal/Modal';


export const HeaderContainer = () => {
  const [modalActive, setModalActive] = useState(false);
  const [isCartMenuVisible, setIsCartMenuVisible] = useState(false);
  const items = useSelector((state) => state.cart.itemsInCart);
  const totalPrice = calcTotalPrice(items);
  return (
    <div className='header__container'>
      <NavbarBurger />

      <div className='logo__container'>
        <Link className='logo__container' to='/'>
          <img className='logo' src={logo} alt='Прима' />
        </Link>
        <p className='header__description'>
          Ассоциация прибрежного рыболовства и марикультуры "ПРиМА"
        </p>
      </div>
      <div className='info__container'>
        <a className='header__phone' href='tel:+74232764713'>
          +7 (423) 2764713
        </a>
        <a className='link' href='#' onClick={() => setModalActive(true)}>
          <User className='icon' />
        </a>
        <div
          className='link'
          onClick={() => setIsCartMenuVisible(!isCartMenuVisible)}
        >
          <ItemsInCart quantity={items.length} />

          <Basket className='icon' />
          {totalPrice > 0 ? <span>{totalPrice} руб.</span> : null}
          {isCartMenuVisible && <CartMenu items={items} onClick={() => null} />}
        </div>
      </div>

      <Modal active={modalActive} setActive={setModalActive}>
        <form className='form' action="" method="get">

          <h3>Авторизоваться</h3>

          <p className='form__text'>Пожалуйста, авторизуйтесь:</p>

          <p className='form__input-wrapper'>
            <label for="name">Логин:</label>
            <input type="tel" name="tel" id="name" required />
          </p>
          <p className='form__input-wrapper'>
            <label for="password">Пароль:</label>
            <input type="password" name="password" id="password" required />
          </p>
          <button className='benefit__button' type="submit">Отправить</button>
        </form>
      </Modal>


    </div>
  );
};
