import React from "react";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Accordion } from '../../components/accordion/accordion';
import { useState } from 'react';
import "./prod-page.css";

export const ProdPage = () => {
    const [active, setActive] = useState('');

    const seasonsList = [
        { id: 1, link: '#', title: 'Горбуша ИП Петрович' },
        { id: 2, link: '#', title: 'Горбуша ООО "Алеша"' },
        { id: 3, link: '#', title: 'Белобрюхая камбала ИП Серов' },
        { id: 4, link: '#', title: 'Кета ООО "Альянс"' },
    ];

    const prod = useSelector(state => state.prods.currentProd);

    if (!prod) return null

    return (

        <>
            <main>
                <h2 className='visually-hidden'>Каталог</h2>
                <div className='path'>
                    <Link to='/'>Главная</Link> | <span>Каталог</span>
                </div>

                <div className='catalog-wrapper'>
                    <div className='accordion-wrapper'>
                        <Accordion
                            title='Вся продукция'
                            active={active}
                            setActive={setActive}
                            list={[]}
                        />
                        <Accordion
                            title='Сезонная продукция'
                            active={active}
                            setActive={setActive}
                            list={seasonsList}
                        />
                        <Accordion
                            title='Местная продукция глубокой переработки'
                            active={active}
                            setActive={setActive}
                            list={seasonsList}
                        />
                        <Accordion
                            title='Дальневосточная продукция глубокой перерабтки'
                            active={active}
                            setActive={setActive}
                            list={seasonsList}
                        />
                        <Accordion
                            title='Кулинарная продукция'
                            active={active}
                            setActive={setActive}
                            list={seasonsList}
                        />
                        <Accordion
                            title='Товары по акции %'
                            active={active}
                            setActive={setActive}
                            list={seasonsList}
                        />
                        <Accordion
                            title='Товары с доставкой по РФ'
                            active={active}
                            setActive={setActive}
                            list={seasonsList}
                        />
                    </div>
                    <div className="prod-content">
                        <div className="prod-content__center">
                            <div className='prod-img-box'>
                                <img className={'prod-img'} src={prod.img} alt={prod.alt} />
                            </div>
                            <p>*Внешний вид товара может отличаться</p>
                        </div>

                        <div className="prod-content__right">
                            <h1>{prod.title}</h1>
                            <span className='prod-content__price'>{prod.price} ₽ / кг</span>
                            <div className='prod-panel'>

                                <div className='prod-quantity'>
                                    <button>
                                        <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.724121 1H15.7241" stroke="#070707" stroke-width="1.5" />
                                        </svg>
                                    </button>
                                    1
                                    <button>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.276367 8H15.2764" stroke="#070707" stroke-width="1.5" />
                                            <path d="M7.77637 15.5L7.77637 0.5" stroke="#070707" stroke-width="1.5" />
                                        </svg>
                                    </button></div>

                                <button className='benefit__button'>
                                    В корзину
                                </button>
                            </div>

                            <section className='parameters'>
                                <p>Характеристики</p>
                                <ul className='parameters__list'>
                                    <li className='parameters__item'>
                                        <span className='parameters__key'>Вес (грамм)</span>
                                        <span className='parameters__value'>1000</span>
                                    </li>
                                    <li className='parameters__item'>
                                        <span className='parameters__key'>Упаковка</span>
                                        <span className='parameters__value'>Пакет</span>
                                    </li>
                                    <li className='parameters__item'>
                                        <span className='parameters__key'>Фасовка</span>
                                        <span className='parameters__value'>Цельная</span>
                                    </li>
                                    <li className='parameters__item'>
                                        <span className='parameters__key'>Способ добычи</span>
                                        <span className='parameters__value'>Сеть ставная</span>
                                    </li>
                                    <li className='parameters__item'>
                                        <span className='parameters__key'>Состояние</span>
                                        <span className='parameters__value'>Свежемороженная</span>
                                    </li>
                                    <li className='parameters__item'>
                                        <span className='parameters__key'>Дата вылова</span>
                                        <span className='parameters__value'>18.05.22</span>
                                    </li>
                                    <li className='parameters__item'>
                                        <span className='parameters__key'>Производитель</span>
                                        <span className='parameters__value'>ООО Прима</span>
                                    </li>
                                </ul>
                            </section>
                        </div>

                    </div>
                </div>
            </main>

        </>
    );
};
