import React, { useState } from 'react';
import './navbarBurger.css';
import logo from '../../assets/images/FooterLogo.png';
import phone from '../../assets/images/phone.svg';
import position from '../../assets/images/position.svg';
import { Link } from 'react-router-dom';

import { AccordionBurgerMenu } from '../accordionBurgerMenu';

export const NavbarBurger = () => {
  const [menuActive, setMenuActive] = useState(false);

  const [active, setActive] = useState('');

  const seasonsList = [
    { id: 1, link: '#', title: 'Горбуша ИП Петрович' },
    { id: 2, link: '#', title: 'Горбуша ООО "Алеша"' },
    { id: 3, link: '#', title: 'Белобрюхая камбала ИП Серов' },
    { id: 4, link: '#', title: 'Кета ООО "Альянс"' },
  ];

  const items = [
    { id: 1, href: '#', value: 'О нас' },
    { id: 2, href: '#', value: 'Каталог' },
    { id: 3, href: '#', value: 'Политика конфиденциальности' },
    { id: 4, href: '#', value: 'Контакты' },
    { id: 4, href: '#', value: 'Проекты' },
    { id: 4, href: '#', value: 'Правила пользования' },
    { id: 4, href: '#', value: 'Запросить льготу' },
  ];

  const header = 'Ассоциация прибрежного рыболовства и марикультуры «ПРиМА»';

  return (
    <>
      <nav className='navbar-burger'>
        <div className='burger-btn' onClick={() => setMenuActive(!menuActive)}>
          <span />
        </div>
      </nav>

      <div
        className={menuActive ? 'menu active-burger' : 'menu'}
        onClick={() => setMenuActive(false)}
      >
        <div className='blur' />
        <div className='menu__content' onClick={(e) => e.stopPropagation()}>
          <a className='logo__container' href='/'>
            <img className='logo-burger' src={logo} alt='Прима' width='160' />
          </a>
          <div className='menu__header'>{header}</div>
          <ul className='burger-menu-list'>
            <li className='burger-menu-item'>
              <Link className='burger-menu-link' to='/catalog'>
                Каталог
              </Link>
            </li>
            {items.map((item) => (
              <>
                <li className='burger-menu-item'>
                  <Link className='burger-menu-link' to={item.href}>
                    {item.value}
                  </Link>
                </li>
                <div className='accordionBurgerMenu-wrapper'>
                  <AccordionBurgerMenu
                    title='Вся продукция'
                    active={active}
                    setActive={setActive}
                    list={[]}
                  />
                  <AccordionBurgerMenu
                    title='Сезонная продукция'
                    active={active}
                    setActive={setActive}
                    list={seasonsList}
                  />
                  <AccordionBurgerMenu
                    title='Местная продукция глубокой переработки'
                    active={active}
                    setActive={setActive}
                    list={seasonsList}
                  />
                  <AccordionBurgerMenu
                    title='Дальневосточная продукция глубокой перерабтки'
                    active={active}
                    setActive={setActive}
                    list={seasonsList}
                  />
                  <AccordionBurgerMenu
                    title='Кулинарная продукция'
                    active={active}
                    setActive={setActive}
                    list={seasonsList}
                  />
                  <AccordionBurgerMenu
                    title='Товары по акции %'
                    active={active}
                    setActive={setActive}
                    list={seasonsList}
                  />
                  <AccordionBurgerMenu
                    title='Товары с доставкой по РФ'
                    active={active}
                    setActive={setActive}
                    list={seasonsList}
                  />
                </div>
              </>
            ))}
          </ul>
          <ul className='burger-menu-list'>
            <li className='burger-menu-item'>
              <a className='burger-menu-link' href='#'>
                Личный кабинет
              </a>
            </li>

            <li className='burger-menu-item'>
              <a className='burger-menu-link' href='#'>
                Корзина
              </a>
            </li>
          </ul>

          <ul className='footer__list'>
            <li className='footer__item'>
              <a className='footer__link' href='#'>
                <img className='footer__icon' src={phone} alt='phone' />
                8(914)673-86-97
              </a>
            </li>

            <li className='footer__item'>
              <a className='footer__link' href='#'>
                <img className='footer__icon' src={position} alt='position' />
                690091 г. Владивосток, ул. Пологая, 63, оф. 9
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
