import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import { Provider } from 'react-redux';
import { store } from './redux';

import { HomePage } from './pages/home-page'
import { Header } from './components/header'
import Footer from './components/footer/footer';
import { CatalogPage } from './pages/catalog-page/catalog-page';
import { ProdPage } from './pages/prod-page/prod-page';
import { AboutPage } from './pages/about-page';
import { PersonalPage } from './pages/personal-page';
import { OrdersPage } from './pages/orders-page';
import { BasketPage } from './pages/basket-page';
import { TeamPage } from './pages/team-page/team-page';
import SeaRiches from "./pages/seaRiches-page";
import FreshwaterRiches from "./pages/freshwaterRiches-page";


function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className='App'>
          <Header />
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/catalog" element={<CatalogPage />} />
            <Route exact path="/about" element={<AboutPage />} />
            <Route exact path="/personal" element={<PersonalPage />} />
            <Route exact path="/orders" element={<OrdersPage />} />
            <Route exact path="/basket" element={<BasketPage />} />
            <Route exact path="/team" element={<TeamPage />} />
            <Route exact path="/app/:title" element={<ProdPage />} />
            <Route exact path="/base/sea-riches" element={<SeaRiches />} />
            <Route exact path="/base/freshwater-riches" element={<FreshwaterRiches />} />
          </Routes>
          <Footer />
        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
