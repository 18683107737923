import React, { useState } from 'react';
import productImage1 from '../../assets/images/fishType1.png';
import productImage2 from '../../assets/images/fishType2.png';
import productImage3 from '../../assets/images/fishType3.png';
import productImage4 from '../../assets/images/fishType4.png';
import NoFish from '../../assets/images/nofish.png';
import './catalog.css';
import { CatalogItem } from '../catalog-item';

export const Catalog = () => {
  const [products, setProducts] = useState([
    {
      id: 1,
      classname: 'hits',
      img: productImage1,
      alt: 'test',
      title: 'Горбуша',
      price: 250,
    },
    {
      id: 2,
      classname: 'hits',
      img: productImage2,
      alt: 'test',
      title: 'Белобрюхая камбала',
      price: 240,
    },
    {
      id: 3,
      classname: 'hits',
      img: productImage3,
      alt: 'test',
      title: 'Кета',
      price: 150,
    },
    {
      id: 4,
      classname: 'hits',
      img: productImage4,
      alt: 'test',
      title: 'Кижуч',
      price: 50,
    },
    {
      id: 1,
      classname: 'hits',
      img: productImage1,
      alt: 'test',
      title: 'Горбуша',
      price: 250,
    },
    {
      id: 2,
      classname: 'hits',
      img: productImage2,
      alt: 'test',
      title: 'Белобрюхая камбала',
      price: 250,
    },
    {
      id: 3,
      classname: 'hits',
      img: productImage3,
      alt: 'test',
      title: 'Кета',
      price: 250,
    },
    {
      id: 4,
      classname: 'hits',
      img: productImage4,
      alt: 'test',
      title: 'Кижуч',
      price: 250,
    },
    {
      id: 1,
      classname: 'hits',
      img: productImage1,
      alt: 'test',
      title: 'Горбуша',
      price: 250,
    },
    {
      id: 2,
      classname: 'hits',
      img: productImage2,
      alt: 'test',
      title: 'Белобрюхая камбала',
      price: 250,
    },
    {
      id: 3,
      classname: 'hits',
      img: productImage3,
      alt: 'test',
      title: 'Кета',
      price: 250,
    },
    {
      id: 4,
      classname: 'hits',
      img: productImage4,
      alt: 'test',
      title: 'Кижуч',
      price: 250,
    },
    {
      id: 1,
      classname: 'hits',
      img: productImage1,
      alt: 'test',
      title: 'Горбуша',
      price: 250,
    },
    {
      id: 2,
      classname: 'hits',
      img: productImage2,
      alt: 'test',
      title: 'Белобрюхая камбала',
      price: 250,
    },
    {
      id: 3,
      classname: 'hits',
      img: productImage3,
      alt: 'test',
      title: 'Кета',
      price: 25,
    },
    {
      id: 4,
      classname: 'hits',
      img: productImage4,
      alt: 'test',
      title: 'Кижуч',
      price: 250,
    },
  ]);

  return (
    <section className='catalog'>
      <ul className={'hits__container'}>

        <img className='nofish' src={NoFish} alt='nofish'></img>

        {products.map((item) => (
          <CatalogItem prod={item} />
        ))}
      </ul>
    </section>
  );
};
