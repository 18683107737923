import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import about from '../../assets/images/about.png';
import experts from '../../assets/images/expert.png';
import projects from '../../assets/images/projects.png';

import './PostList.css';

const PostList = () => {
  const [posts, setPosts] = useState([
    { id: 1, img: about, title: 'О нас', link: '/about' },
    { id: 2, img: experts, title: 'Экспертный Совет Ассоциации', link: '/' },
    { id: 3, img: projects, title: 'Наши проекты', link: '/' },
  ]);

  return (
    <ul className='post__list'>
      {posts.map((post) => (
        <li key={post.id} className='post'>
          <img className='post__img' src={post.img} alt={post.alt} />
          <div className='post__description'>
            <h4 className='post__title'>{post.title}</h4>
            <Link to={post.link} className='post__button'>
              Подробнее
            </Link>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default PostList;
