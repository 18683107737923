import React, { useState } from 'react';
import productImage1 from '../../assets/images/fishType1.png';
import productImage2 from '../../assets/images/fishType2.png';
import productImage3 from '../../assets/images/fishType3.png';
import productImage4 from '../../assets/images/fishType4.png';

import NoFish from '../../assets/images/nofish.png';

import classnames from 'classnames';

import './hits-section.css';

export const HitsSection = () => {
  const [products, setProducts] = useState([
    {
      id: 1,
      classname: 'hits',
      img: productImage1,
      alt: 'test',
      title: 'Горбуша',
      price: '250',
    },
    {
      id: 2,
      classname: 'hits',
      img: productImage2,
      alt: 'test',
      title: 'Белобрюхая камбала',
      price: '250',
    },
    {
      id: 3,
      classname: 'hits',
      img: productImage3,
      alt: 'test',
      title: 'Кета',
      price: '250',
    },
    {
      id: 4,
      classname: 'hits',
      img: productImage4,
      alt: 'test',
      title: 'Кижуч',
      price: '250',
    },
  ]);

  return (
    <section className='hits__section'>
      <h2 className='hits__title'>Хиты продаж</h2>
      <ul className={'hits__container'}>
      <img className='nofish' src={NoFish} alt='nofish'></img>
        <div className={'button__container'}>
          <button className={'prev__button'}>
            <svg
              width='18'
              height='18'
              viewBox='0 0 18 18'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M16.4146 9.06641L8.91455 16.5664M0.789551 9.06641H16.4146H0.789551ZM16.4146 9.06641L8.91455 1.56641L16.4146 9.06641Z' />
            </svg>
          </button>
          <button className={classnames('next__button', 'active')}>
            <svg
              width='18'
              height='18'
              viewBox='0 0 18 18'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M16.4146 9.06641L8.91455 16.5664M0.789551 9.06641H16.4146H0.789551ZM16.4146 9.06641L8.91455 1.56641L16.4146 9.06641Z' />
            </svg>
          </button>
        </div>
        {products.map((prod) => (
          <li key={prod.id} className={classnames('product', prod.classname)}>
            <img className={'product__img'} src={prod.img} alt={prod.alt} />
            <h3 className={'product__title'}>{prod.title}</h3>
            <div className={'product__container'}>
              <div className={'span__container'}>
                {prod.classname === 'promotion' && (
                  <span className={'product__oldprice'}>
                    {prod.price} &#8381; / кг
                  </span>
                )}
                <span className={'product__price'}>
                  {prod.price} &#8381; / кг
                </span>
              </div>
              <button className={'addButton'}>
                <svg
                  className={'addButton__svg'}
                  width='17'
                  height='17'
                  viewBox='0 0 16 16'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M0.729492 7.94824H15.7295' strokeWidth='1.5' />
                  <path
                    d='M8.22949 15.4482L8.22949 0.448243'
                    strokeWidth='1.5'
                  />
                </svg>
              </button>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};
