import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";

export const Navbar = () => {
  const [nav__item, setItem] = useState([
    { link: "/about", title: "О нас", pages: [] },
    { link: "/catalog", title: "Каталог", pages: [] },
    { link: "/team", title: "Команда", pages: [] },
    { link: "/", title: "Проекты", pages: [] },
    {
      link: "/base",
      title: "База знаний",
      pages: [
        {
          link: "/base/sea-riches",
          title: "Морские Богатства",
        },
        {
          link: "/base/freshwater-riches",
          title: "Пресноводные богатства",
        },
      ],
    },
  ]);
  return (
    <nav className="header__navbar">
      <ul className="nav__list">
        {nav__item.map((item, key) => {
          let pages = item.pages.map((subpage) => {
            return (
              <>
                <Link className="nav__link sub__link" to={subpage.link}>
                  <li>{subpage.title}</li>
                </Link>
              </>
            );
          });
          return (
            <li key={key} className="nav__item">
              <Link className="nav__link" to={item.link}>
                {item.title}
              </Link>
              <ul className="nav__sub-list">{pages}</ul>
            </li>
          );
        })}
      </ul>
      <button
        className="benefit__button"
        onClick={() => alert("временно не работает")}
      >
        Запросить льготу
      </button>
    </nav>
  );
};
