import React, { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import './carousel.css';

export const Carousel = ({ children }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [paused, setPaused] = useState(true);

  const updateIndex = (newIndex) => {
    if (newIndex < 0) {
      newIndex = React.Children.count(children) - 1;
    } else if (newIndex >= React.Children.count(children)) {
      newIndex = 0;
    }

    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) {
        updateIndex(activeIndex + 1);
      }
    }, 5000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  const handlers = useSwipeable({
    onSwipedLeft: () => updateIndex(activeIndex + 1),
    onSwipedRight: () => updateIndex(activeIndex - 1),
  });

  return (
    <div className='wrapper'>
      <div className='indicators'>
        <button
          className='left__arrow'
          onClick={() => {
            updateIndex(activeIndex - 1);
          }}
        ></button>
        <div className='pagination'>
          {React.Children.map(children, (child, index) => {
            return (
              <button
                className={`${
                  index === activeIndex ? 'slide__dot active' : 'slide__dot'
                }`}
                onClick={() => {
                  updateIndex(index);
                }}
              ></button>
            );
          })}
        </div>
        <button
          className='right__arrow'
          onClick={() => {
            updateIndex(activeIndex + 1);
          }}
        ></button>
      </div>
      <div
        {...handlers}
        className='carousel'
        onMouseEnter={() => setPaused(true)}
        onMouseLeave={() => setPaused(false)}
      >
        <div
          className='inner'
          style={{ transform: `translateX(-${activeIndex * 100}%)` }}
        >
          {React.Children.map(children, (child, index) => {
            return React.cloneElement(child, { width: '100%' });
          })}
        </div>
      </div>
    </div>
  );
};
