import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/FooterLogo.png';
import phone from '../../assets/images/phone.svg';
import position from '../../assets/images/position.svg';

import './footer.css';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer__container'>
        <Link className='logo__container' to='/'>
          <img className='logo' src={logo} alt='Прима' />
        </Link>
        <ul className='footer__list'>
          <li className='footer__item'>
            <Link className='footer__link' to='/about'>
              О нас
            </Link>
          </li>

          <li className='footer__item'>
            <Link className='footer__link' to='/catalog'>
              Каталог
            </Link>
          </li>

          <li className='footer__item'>
            <Link className='footer__link' to='/'>
              Политика конфиденциальности
            </Link>
          </li>
        </ul>

        <ul className='footer__list'>
          <li className='footer__item'>
            <Link className='footer__link' to='/'>
              Контакты
            </Link>
          </li>

          <li className='footer__item'>
            <Link className='footer__link' to='/'>
              Проекты
            </Link>
          </li>

          <li className='footer__item'>
            <Link className='footer__link' to='/'>
              Правила пользования
            </Link>
          </li>
        </ul>

        <ul className='footer__list'>
          <li className='footer__item'>
            <Link className='footer__link' to='/'>
              <img className='footer__icon' src={phone} alt='phone' />
              8(914)673-86-97
            </Link>
          </li>

          <li className='footer__item'>
            <p className='footer__link' >
              <img className='footer__icon' src={position} alt='position' />
              690091 г. Владивосток, ул. Пологая, 63, оф. 9
            </p>
          </li>
        </ul>
      </div>
      <span className='footer__copyright'>© 2019-2022 ПРИМА»</span>

      <a href='https://pena.digital'>
        <svg width="50" height="15" viewBox="0 0 780 224" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M183.962 82.5902C181.63 59.9122 170.96 38.9079 154.023 23.6486C137.085 8.38925 115.085 -0.0381468 92.2875 -0.000115982C89.0507 0.00132177 85.8162 0.170634 82.5969 0.507139C59.9291 2.88732 38.9456 13.5768 23.6934 30.5138C8.44117 47.4508 0.000738232 69.4357 0 92.2281H0V223.983H39.5264V167.987C54.9806 178.738 73.3625 184.487 92.1886 184.456C95.4254 184.455 98.66 184.286 101.879 183.949C113.925 182.683 125.603 179.057 136.247 173.278C146.891 167.498 156.292 159.679 163.915 150.267C171.537 140.854 177.23 130.032 180.67 118.419C184.11 106.806 185.229 94.629 183.962 82.5836V82.5902ZM133.197 125.41C128.855 130.804 123.486 135.283 117.402 138.587C111.317 141.891 104.637 143.955 97.7487 144.66C95.9015 144.852 94.0457 144.948 92.1886 144.95C80.2348 144.939 68.6399 140.864 59.3069 133.395C49.974 125.926 43.4575 115.505 40.8271 103.845C38.1968 92.1838 39.6087 79.9752 44.8313 69.2226C50.0539 58.47 58.7768 49.8123 69.5683 44.6705C80.3597 39.5288 92.5786 38.2084 104.219 40.9262C115.86 43.644 126.231 50.2384 133.63 59.6272C141.029 69.0159 145.017 80.6411 144.938 92.5946C144.859 104.548 140.719 116.12 133.197 125.41Z" fill="white" />
          <path d="M287.093 0C283.857 0.00143775 280.622 0.17075 277.403 0.507255C253.915 2.98079 232.272 14.3705 216.933 32.3288C201.594 50.2871 193.729 73.4455 194.959 97.0308C196.189 120.616 206.42 142.831 223.543 159.096C240.667 175.362 263.377 184.439 286.995 184.456C290.231 184.455 293.466 184.286 296.685 183.949C313.62 182.146 329.728 175.704 343.236 165.332C356.744 154.961 367.127 141.062 373.241 125.167H328.188L328.003 125.397C322.232 132.531 314.699 138.033 306.149 141.362C297.598 144.69 288.327 145.729 279.253 144.374C270.178 143.02 261.614 139.32 254.408 133.641C247.202 127.961 241.603 120.499 238.166 111.991H377.167C380.094 98.5256 379.971 84.5757 376.807 71.1637C373.643 57.7517 367.518 45.2175 358.882 34.4796C350.245 23.7417 339.316 15.072 326.894 9.10599C314.473 3.13993 300.873 0.0286079 287.093 0V0ZM238.166 72.465C241.764 63.6083 247.698 55.894 255.336 50.1443C262.973 44.3947 272.027 40.8251 281.533 39.8162C283.38 39.6244 285.236 39.5277 287.093 39.5264C297.592 39.5088 307.855 42.6374 316.558 48.5085C325.262 54.3796 332.006 62.724 335.922 72.465H238.166Z" fill="white" />
          <path d="M474.804 0.000103238C459.696 -0.0238769 444.877 4.13015 431.983 12.0029V0.000103238H392.457V184.456H431.983V82.3467C431.983 70.9901 436.495 60.0986 444.525 52.0682C452.556 44.0379 463.447 39.5265 474.804 39.5265C486.16 39.5265 497.052 44.0379 505.082 52.0682C513.112 60.0986 517.624 70.9901 517.624 82.3467V184.456H557.15V82.3467C557.15 60.507 548.474 39.5618 533.031 24.1189C517.588 8.67588 496.643 0.000103238 474.804 0.000103238Z" fill="white" />
          <path d="M754.802 104.014V0.000166326H715.275V16.4695C699.823 5.71348 681.44 -0.0356489 662.613 0.000166326C659.376 0.00160408 656.142 0.170916 652.922 0.507421C629.435 2.98096 607.791 14.3706 592.453 32.329C577.114 50.2873 569.249 73.4456 570.479 97.0309C571.709 120.616 581.939 142.831 599.063 159.097C616.186 175.362 638.897 184.439 662.514 184.456C665.751 184.455 668.985 184.286 672.205 183.949C695.177 181.598 716.413 170.654 731.659 153.31C743.505 169.158 760.66 180.214 779.986 184.456V143.02C772.486 139.623 766.123 134.138 761.657 127.221C757.191 120.304 754.811 112.247 754.802 104.014ZM703.523 125.398C699.18 130.79 693.811 135.268 687.726 138.572C681.641 141.876 674.962 143.941 668.074 144.647C666.227 144.839 664.371 144.935 662.514 144.937C649.01 144.936 636.021 139.752 626.228 130.453C616.435 121.155 610.585 108.452 609.885 94.9659C609.186 81.4799 613.689 68.2397 622.467 57.9776C631.245 47.7156 643.628 41.2146 657.059 39.8164C658.904 39.6244 660.758 39.5276 662.613 39.5265C672.563 39.535 682.308 42.3602 690.72 47.6754C699.132 52.9906 705.867 60.5785 710.146 69.5615C714.426 78.5446 716.075 88.5556 714.903 98.4367C713.731 108.318 709.785 117.665 703.523 125.398Z" fill="white" />
        </svg>
      </a>
    </footer>
  );
};

export default Footer;
