import React from 'react';
import { Link } from 'react-router-dom';
import './catalog-page.css';

import { Catalog } from '../../components/catalog/catalog';
import { Accordion } from '../../components/accordion/accordion';
import { useState } from 'react';

export const CatalogPage = () => {
  const [active, setActive] = useState('');

  const seasonsList = [
    { id: 1, link: '#', title: 'Горбуша ИП Петрович' },
    { id: 2, link: '#', title: 'Горбуша ООО "Алеша"' },
    { id: 3, link: '#', title: 'Белобрюхая камбала ИП Серов' },
    { id: 4, link: '#', title: 'Кета ООО "Альянс"' },
  ];

  return (
    <>
      <main>
        <h2 className='visually-hidden'>Каталог</h2>
        <div className='path'>
          <Link to='/'>Главная</Link> | <span>Каталог</span>
        </div>

        <div className='catalog-wrapper'>
          <div className='accordion-wrapper'>
            <Accordion
              title='Вся продукция'
              active={active}
              setActive={setActive}
              list={[]}
            />
            <Accordion
              title='Сезонная продукция'
              active={active}
              setActive={setActive}
              list={seasonsList}
            />
            <Accordion
              title='Местная продукция глубокой переработки'
              active={active}
              setActive={setActive}
              list={seasonsList}
            />
            <Accordion
              title='Дальневосточная продукция глубокой перерабтки'
              active={active}
              setActive={setActive}
              list={seasonsList}
            />
            <Accordion
              title='Кулинарная продукция'
              active={active}
              setActive={setActive}
              list={seasonsList}
            />
            <Accordion
              title='Товары по акции %'
              active={active}
              setActive={setActive}
              list={seasonsList}
            />
            <Accordion
              title='Товары с доставкой по РФ'
              active={active}
              setActive={setActive}
              list={seasonsList}
            />
          </div>
          <Catalog />
        </div>
      </main>

    </>
  );
};
