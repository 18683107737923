import React, { useState } from 'react';
import fishIcon from '../../assets/images/fish.svg';
import octoIcon from '../../assets/images/octopus.svg';
import seaIcon from '../../assets/images/sea.svg';
import chefIcon from '../../assets/images/chef.svg';
import './category-list.css';

export const CategoryList = () => {
  const [cards, setCards] = useState([
    {
      id: 1,
      address: '#',
      imgAddress: fishIcon,
      alt: '#',
      title: 'Сезонная социальная продукция',
    },
    {
      id: 2,
      address: '#',
      imgAddress: octoIcon,
      alt: '#',
      title: 'Местная продукция глубокой переработки',
    },
    {
      id: 3,
      address: '#',
      imgAddress: seaIcon,
      alt: '#',
      title: 'Дальневосточная продукция глубокой переработки',
    },
    {
      id: 4,
      address: '#',
      imgAddress: chefIcon,
      alt: '#',
      title: 'Кулинарная продукция',
    },
  ]);

  return (
    <ul className='category__list'>
      {cards.map((card) => (
        <a key={card.id} className='card' href={card.address}>
          <div className='img__container'>
            <img className='card__img' src={card.imgAddress} alt={card.alt} />
          </div>
          <span className='card__title'>{card.title}</span>
        </a>
      ))}
    </ul>
  );
};
