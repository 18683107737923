import React from "react";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Accordion } from '../../components/accordion/accordion';
import { useState } from 'react';
import "./personal-page.css";

import Ava from '../../assets/images/ava.png';

export const PersonalPage = () => {
    const [active, setActive] = useState('');

    const orders = [
        { id: 1, link: '#', title: 'Отслеживание' },
        { id: 2, link: '#', title: 'История заказов' },
    ];



    return (

        <>
            <main>
                <h2 className='visually-hidden'>Каталог</h2>
                <div className='path'>
                    <Link to='/'>Главная</Link> | <span>Личный кабинет</span> | <span>Личные данные</span>
                </div>

                <div className='catalog-wrapper'>
                    <div className='accordion-wrapper'>

                        <Accordion
                            title='Сезонная продукция'
                            active={active}
                            setActive={setActive}
                            list={orders}
                        />
                        <Accordion
                            title='Личные данные'
                            active={active}
                            setActive={setActive}
                            list={[]}
                        />


                    </div>
                    <div className="prod-content">
                        <div className="prod-content__center">
                            <div className='prod-img-box'>
                                <img className='' src={Ava} alt='test' />
                            </div>

                        </div>

                        <div className="prod-content__right">
                            <h1>Васильев Василий Васильевич</h1>



                            <section className='parameters'>
                                <p>Характеристики</p>
                                <ul className='parameters__list'>
                                    <li className='parameters__item'>
                                        <span className='parameters__key'>Возраст</span>
                                        <span className='parameters__value'>40 лет</span>
                                    </li>
                                    <li className='parameters__item'>
                                        <span className='parameters__key'>Категория льготы </span>
                                        <span className='parameters__value'>нет</span>
                                    </li>
                                    <li className='parameters__item'>
                                        <span className='parameters__key'>Адрес доставки</span>
                                        <span className='parameters__value'>690091 г. Владивосток, ул. Пологая, 63, оф. 9</span>
                                    </li>
                                    <li className='parameters__item'>
                                        <span className='parameters__key'>Телефон</span>
                                        <span className='parameters__value'>+7 (423) 2764713</span>
                                    </li>
                                    <li className='parameters__item'>
                                        <span className='parameters__key'>Дополнительная информация</span>
                                        <span className='parameters__value'>нет</span>
                                    </li>
                                    <li className='parameters__item'>
                                        <span className='parameters__key'>Дата создания</span>
                                        <span className='parameters__value'>18.05.22</span>
                                    </li>

                                </ul>

                                <button className='benefit__button'>Редактировать</button>
                            </section>
                        </div>

                    </div>
                </div>
            </main>

        </>
    );
};
