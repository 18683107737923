import React from "react";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Accordion } from '../../components/accordion/accordion';
import { useState } from 'react';
import "./orders-page.css";

import Ava from '../../assets/images/ava.png';

export const OrdersPage = () => {
    const [active, setActive] = useState('');

    const orders = [
        { id: 1, link: '#', title: 'Отслеживание' },
        { id: 2, link: '#', title: 'История заказов' },
    ];

    return (

        <>
            <main>
                <h2 className='visually-hidden'>Каталог</h2>
                <div className='path'>
                    <Link to='/'>Главная</Link> | <span>Личный кабинет</span> | <span>Личные данные</span>
                </div>

                <div className='catalog-wrapper'>
                    <div className='accordion-wrapper'>

                        <Accordion
                            title='Сезонная продукция'
                            active={active}
                            setActive={setActive}
                            list={orders}
                        />
                        <Accordion
                            title='Личные данные'
                            active={active}
                            setActive={setActive}
                            list={[]}
                        />
                    </div>

                    <div className="orders-content">

                        <table>

                            <tbody>
                                <tr>
                                    <td>Пункт 1</td>
                                    <td>Пункт 2</td>
                                    <td>Пункт 3</td>

                                </tr>
                                <tr>
                                    <td>Пункт 1</td>
                                    <td>Пункт 2</td>
                                    <td>Пункт 3</td>
                                </tr>
                                <tr>
                                    <td>Пункт 1</td>
                                    <td>Пункт 2</td>
                                    <td>Пункт 3</td>
                                </tr>
                                <tr>
                                    <td>Пункт 1</td>
                                    <td>Пункт 2</td>
                                    <td>Пункт 3</td>
                                </tr>
                                <tr>
                                    <td>Пункт 1</td>
                                    <td>Пункт 2</td>
                                    <td>Пункт 3</td>
                                </tr>
                                <tr>
                                    <td>Пункт 1</td>
                                    <td>Пункт 2</td>
                                    <td>Пункт 3</td>
                                </tr>
                                <tr>
                                    <td>Пункт 1</td>
                                    <td>Пункт 2</td>
                                    <td>Пункт 3</td>
                                </tr>
                            </tbody>
                        </table>


                    </div>
                </div>
            </main>

        </>
    );
};
