import React from "react";
import "./header.css";

import { HeaderContainer } from "../header-container";
import { Navbar } from "../navbar/navbar";

export const Header = () => {
  return (
    <header className="header">
      <HeaderContainer />
      <Navbar />
    </header>
  );
};
