import React from 'react';
import { Link } from 'react-router-dom';
import './about-page.css';

import participants from '../../assets/images/participants.jpg';


export const AboutPage = () => {
  return (
    <>
      <main className='about-main'>
        <div className="container-about">
          <h2 className='visually-hidden'>О нас</h2>
          <div className='path'>
            <Link to='/'>Главная</Link> | <span>О нас</span>
          </div>

          <section className='about-promo'>

            <h1>
              Ассоциация прибрежного рыболовства
              и марикультуры «ПРиМА»
            </h1>

          </section>

          <article className='about-info'>



            <ul className='about-info__list'>

              <h3 className='about-info__title'> Основные направления деятельности Ассоциация ПРИМА</h3>

              <li>
                1. От рыбака до едока – планирование и организация промыслов от выловленного и\или
                выращенного сырца до доставки потребителю через создание системы
                снабжения внутреннего рынка живой и охлажденной сезонной морепродукции.
              </li>

              <li>
                2. Цифровая трансформация прибрежного рыболовства и марикультуры – подготовка предприятий
                членов Ассоциации к переходу на цифровые государственные сервисы, такие как СИГУР, ЭПЖ, Плавник ID и др.
              </li>

              <li>
                3. Приморские рыбаки – фронтовикам - создание механизма обеспечения рыбной продукцией ветеранов ВОВ и
                детей войны города Владивостока на благотворительной основе.
              </li>

              <li>
                4. Маломасштабное рыболовство – создание в РФ нового вида рыболовства для повышения уровня
                социально-экономического развития прибрежных регионов за счет доступа субъектов малого
                предпринимательства к одуемым видам промысла и роста налоговых поступлений от реализации местных
                сезонных морепродуктов в муниципальные и региональные бюджеты прибрежных субъектов Российской Федерации
              </li>
            </ul>


            {/* <p className='about-info__text'>
              Некоммерческая организация «Ассоциация прибрежного рыболовства и марикультуры
              «ПРИМА» является добровольным объединением коммерческих и некоммерческих организаций,
              физических лиц, хозяйствующих субъектов осуществляющих свою деятельность в сфере
              рыбного хозяйства и аквакультуры в целях координации их предпринимательской
              деятельности, представления и защиты имущественных интересов в соответствии с
              законодательством РФ.
            </p>

            <p className='about-info__text'>
              Ассоциация была создана 04.09.2017 г. во Владивостоке, одним из основных видов
              деятельности является представление интересов членов Ассоциации в отношениях с
              государственными органами, коммерческими, некоммерческими и общественными
              организациями, координация работы членов Ассоциации с отраслевой наукой,
              образовательными учреждениями, проектно-конструкторскими, рыбоохранными и другими
              организациями. На конец 2019 г в Ассоциацию входит 21 рыбодобывающая организация.
            </p> */}
          </article>

          <article className='about-info'>
            <div className="about-info__numbers">
              <span>349 +</span>
              <p>Участников ассоциации</p>
            </div>

            {/* <div className="about-info__numbers">
              <span>349 +</span>
              <p>Участников ассоциации</p>
            </div> */}
          </article>

        </div>

        <section className='participants'>
          <div className="container-about">
            <h2>
              Члены ассоциации
            </h2>

            <div className="participants-content">
              <div className='participants-info'>
                <p>
                  Некоммерческая организация «Ассоциация прибрежного рыболовства и марикультуры
                  «ПРИМА» является добровольным объединением коммерческих и некоммерческих
                  организаций, физических лиц, хозяйствующих субъектов осуществляющих свою
                  деятельность в сфере рыбного хозяйства и аквакультуры в целях координации
                  их предпринимательской деятельности, представления и защиты имущественных
                  интересов в соответствии с законодательством РФ.
                </p>

                <a href='#' className='button-about'>
                  Участники
                </a>
              </div>

              <img src={participants} alt='participants' />
            </div>

          </div>
        </section>

        <section className='request'>
          <div className="container-about">

            <div className="request__wrapper">
              <div className="request__info">
                <h2>Подать запрос на вступление
                  в ассоциацию</h2>

                <p>
                  Некоммерческая организация «Ассоциация прибрежного рыболовства и
                  марикультуры «ПРИМА» является добровольным объединением коммерческих
                  и некоммерческих организаций, физических лиц, хозяйствующих субъектов
                  осуществляющих свою деятельность в сфере рыбного хозяйства и аквакультуры
                  в целях координации их предпринимательской деятельности, представления
                  и защиты имущественных интересов в соответствии с законодательством РФ.
                </p>
              </div>

              <div className="request__form">
                <form action="" method="get">

                  <input className='request__input' type="text" name="name" id="name" placeholder='ФИО заявителя' required />

                  <input className='request__input' type="tel" name="tel" id="tel" placeholder='E-mail' required />

                  <input className='request__input' type="email" name="email" id="email" placeholder='Телефон' required />

                  <input className='request__input' type="text" name="name" id="name" placeholder='Название организации или ИП' required />

                  <input className='request__input' type="text" name="name" id="name" placeholder='ИНН организации или ИП' required />

                  <button className='button-request' type="submit">Отправить</button>
                </form>
              </div>
            </div>

          </div>
        </section>

        <section className='about-legal'>
          <div className="container-about">

            <div className="about-legal__wrapper">
              <h2>
                Юридическая информация
                об организации
              </h2>

              <ul>
                <li>
                  Адрес: Российская Федерация, Приморский край, 690091 г. Владивосток, ул. Пологая, 63, оф. 9
                </li>

                <li>
                  Руководитель Ассоциации – Майсс Артур Айварович
                </li>

                <li>
                  ИНН 2536305618 ОГРН 117250000130
                </li>
              </ul>
            </div>
          </div>
        </section>

      </main>
    </>
  );
};

