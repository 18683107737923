import axios from "axios";
import TableFish from "../../components/tableFish";
import "./style.css";
import { useEffect, useState } from "react";

export default function SeaRiches() {
  const [fishMass, setFishMass] = useState();

  useEffect(() => {
    axios.get("data/sea-riches.json").then((response) => {
      console.log(response.data);
      setFishMass(response.data);
    });
  }, []);
  if (!fishMass) return null;
  return (
    <>
      <main className="page-riches">
        <h2 className="page-riches__title">Морские богатства</h2>
        <TableFish fishMass={fishMass}></TableFish>
      </main>
    </>
  );
}
