import React from "react";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import Img from '../../assets/images/basket.png';

import "./basket-page.css";

export const BasketPage = () => {


    return (

        <>
            <main>
                <h2 className='visually-hidden'>Каталог</h2>
                <div className='path'>
                    <Link to='/'>Главная</Link> | <span>Корзина</span>
                </div>

                <div className='basket-wrapper'>

                    <ul className='basket-list'>

                        <li className='basket-item'>
                            <img className='basket-img' src={Img} alt='test' />

                            <span className='basket-item-name'>Горбуша</span>

                            <div className='basket-price'>
                                <span className='basket-price__top'>350 ₽</span>
                                <span>вес: кг</span>
                            </div>

                            <div class="prod-quantity">
                                <button>
                                    <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.724121 1H15.7241" stroke="#070707" stroke-width="1.5">
                                        </path>
                                    </svg>
                                </button>1<button>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.276367 8H15.2764" stroke="#070707" stroke-width="1.5">
                                        </path>
                                        <path d="M7.77637 15.5L7.77637 0.5" stroke="#070707" stroke-width="1.5">
                                        </path>
                                    </svg>
                                </button>
                            </div>

                            <div className='basket-price'>
                                <span className='basket-price__top'>350 ₽</span>
                                <span>вес: кг</span>
                            </div>

                        </li>

                        <li className='basket-item'>
                            <img className='basket-img' src={Img} alt='test' />

                            <span className='basket-item-name'>Горбуша</span>

                            <div className='basket-price'>
                                <span className='basket-price__top'>350 ₽</span>
                                <span>вес: кг</span>
                            </div>

                            <div class="prod-quantity">
                                <button>
                                    <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.724121 1H15.7241" stroke="#070707" stroke-width="1.5">
                                        </path>
                                    </svg>
                                </button>1<button>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.276367 8H15.2764" stroke="#070707" stroke-width="1.5">
                                        </path>
                                        <path d="M7.77637 15.5L7.77637 0.5" stroke="#070707" stroke-width="1.5">
                                        </path>
                                    </svg>
                                </button>
                            </div>

                            <div className='basket-price'>
                                <span className='basket-price__top'>350 ₽</span>
                                <span>вес: кг</span>
                            </div>

                        </li>

                        <li className='basket-item'>
                            <img className='basket-img' src={Img} alt='test' />

                            <span className='basket-item-name'>Горбуша</span>

                            <div className='basket-price'>
                                <span className='basket-price__top'>350 ₽</span>
                                <span>вес: кг</span>
                            </div>

                            <div class="prod-quantity">
                                <button>
                                    <svg width="16" height="2" viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.724121 1H15.7241" stroke="#070707" stroke-width="1.5">
                                        </path>
                                    </svg>
                                </button>1<button>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.276367 8H15.2764" stroke="#070707" stroke-width="1.5">
                                        </path>
                                        <path d="M7.77637 15.5L7.77637 0.5" stroke="#070707" stroke-width="1.5">
                                        </path>
                                    </svg>
                                </button>
                            </div>

                            <div className='basket-price'>
                                <span className='basket-price__top'>350 ₽</span>
                                <span>вес: кг</span>
                            </div>

                        </li>

                    </ul>

                    <div className='basket-info'>

                        <div className='basket-info__wrapper'>
                            <span className='basket-info__title'>Итоговая стоимость:</span> <span className='basket-info__price'>15 500 ₽</span>
                        </div>

                        <div className='basket-info__wrapper'>
                            <span className='basket-info__title'>Общий вес:</span> <span className='basket-info__title'>3 500 кг</span>
                        </div>

                        <button class="benefit__button">Оформить заказ</button>

                    </div>

                </div>
            </main>

        </>
    );
};
