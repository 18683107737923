import React from 'react';
import { Carousel } from '../../components/carousel';
import { CategoryList } from '../../components/category-list';
import './home-page.css';

import Slide0 from '../../assets/images/slide0.jpg';
import Slide1 from '../../assets/images/slide1.jpg';
import Slide2 from '../../assets/images/slide2.jpg';
import Slide3 from '../../assets/images/slide3.jpg';
import Slide4 from '../../assets/images/slide4.jpg';
import Slide5 from '../../assets/images/slide5.jpg';
import Slide6 from '../../assets/images/slide6.jpg';

import { HitsSection } from '../../components/hits-section/hits-section';

import PromotionsSection from '../../components/PromotionsSection/PromotionsSection';
import DeliverySection from '../../components/DeliverySection/DeliverySection';
import PostList from '../../components/PostList/PostList';

export const HomePage = () => {
  return (
    <>
      <Carousel>
        <a href='test' className='item__container'>
          <img className='item__img' src={Slide0} alt='test' />
          <p className='item__text'>
            Ассоциация прибрежного рыболовства и марикультура "ПРиМА"
          </p>
        </a>

        <a href='test' className='item__container'>
          <img className='item__img' src={Slide1} alt='test' />
          {/* <p className='item__text'>
            'Ассоциация прибрежного рыболовства и марикультура "ПРиМА"'
          </p> */}
        </a>

        <a href='test' className='item__container'>
          <img className='item__img' src={Slide2} alt='test' />
          {/* <p className='item__text'>
            'Ассоциация прибрежного рыболовства и марикультура "ПРиМА"'
          </p> */}
        </a>

        <a href='test' className='item__container'>
          <img className='item__img' src={Slide3} alt='test' />
          {/* <p className='item__text'>
            'Ассоциация прибрежного рыболовства и марикультура "ПРиМА"'
          </p> */}
        </a>

        <a href='test' className='item__container'>
          <img className='item__img' src={Slide4} alt='test' />
          {/* <p className='item__text'>
            'Ассоциация прибрежного рыболовства и марикультура "ПРиМА"'
          </p> */}
        </a>

        <a href='test' className='item__container'>
          <img className='item__img' src={Slide5} alt='test' />
          {/* <p className='item__text'>
            'Ассоциация прибрежного рыболовства и марикультура "ПРиМА"'
          </p> */}
        </a>

        <a href='test' className='item__container'>
          <img className='item__img' src={Slide6} alt='test' />
          {/* <p className='item__text'>
            'Ассоциация прибрежного рыболовства и марикультура "ПРиМА"'
          </p> */}
        </a>

      </Carousel>
      <CategoryList />
      <HitsSection />
      <PromotionsSection />
      <DeliverySection />
      <PostList />

    </>
  );
};
