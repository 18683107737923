import React from 'react';
import { calcTotalPrice } from '../utils';
import { CartItem } from '../сart-item';
import './cart-menu.css';

export const CartMenu = ({ items, onClick }) => {
  return (
    <div className='cart-menu'>
      <div className='cart-menu__products-list'>
        {items.length > 0
          ? items.map((prod) => (
              <CartItem
                key={prod.title}
                price={prod.price}
                title={prod.title}
                id={prod.id}
              />
            ))
          : 'Корзина пуста'}
      </div>
      {items.length > 0 ? (
        <div className='cart-menu__arrange'>
          <div className='cart-menu__total-price'>
            <span>Итого:</span>
            <span>{calcTotalPrice(items)} руб.</span>
          </div>
          <button className='addButton' onClick={onClick}>
            Оформить заказ
          </button>
        </div>
      ) : null}
    </div>
  );
};
