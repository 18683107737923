import React from 'react';
import { Link } from 'react-router-dom';
import './team-page.css';

import participants from '../../assets/images/participants.jpg';


export const TeamPage = () => {
  return (
    <>
      <main className='about-main'>
        <div className="container-about">
          <h2 className='visually-hidden'>Команда</h2>
          <div className='path'>
            <Link to='/'>Главная</Link> | <span>Команда</span>
          </div>

          <section className='about-promo'>

            <h1>
              Ассоциация прибрежного рыболовства
              и марикультуры «ПРиМА»
            </h1>

          </section>

          <article className='team'>

            <h3>Совет Ассоциации ПРИМА</h3>

            <ul>
              <li>Направление «Взаимодействие со структурами Росрыболовства» - Жукова Т.В.;</li>
              <li>Направление «Рыболовство с использованием маломерных судов» - Тарев А.А.</li>
              <li>Направление «Рыболовство стационарными орудиями лова» - Тымченко А.В.;</li>
              <li>Направление «Рыболовство водолазным способом» - Гапонов Р.В.;</li>
              <li>Направление «Правовое обеспечение процессов рыболовства и марикультуры» - Вялых С.В.;</li>
              <li>Направление «Цифровое обеспечение процессов рыболовства и марикультуры» - Радаев И. Р. </li>
              <li>Направление «Инновации в рыболовстве и марикультуре» - Майсс А.А.</li>
            </ul>

              </article>

        </div>

      </main>
    </>
  );
};

