import React from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { deleteItemFromCart, setItemInCart } from '../../redux/cart/reducer';
import { setCurrentProd } from '../../redux/prods/reducer';
import './catalog-item.css';

export const CatalogItem = ({ prod }) => {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.cart.itemsInCart);
  const isItemInCart = items.some((item) => item.id === prod.id);

  const handleClick = (e) => {
    e.stopPropagation();
    if (isItemInCart) {
      dispatch(deleteItemFromCart(prod.id));
    } else {
      dispatch(setItemInCart(prod));
    }
  };

  const navigate = useNavigate();
  const handleClickItem = () => {
    dispatch(setCurrentProd(prod));
    navigate(`/app/${prod.title}`);
  };

  return (
    <li
      key={prod.id}
      className={classnames('product', prod.classname)}
      onClick={handleClickItem}
    >
      <img className={'product__img'} src={prod.img} alt={prod.alt} />
      <h3 className={'product__title'}>{prod.title}</h3>
      <div className={'product__container'}>
        <div className={'span__container'}>
          {prod.classname === 'promotion' && (
            <span className={'product__oldprice'}>
              {prod.price} &#8381; / кг
            </span>
          )}
          <span className={'product__price'}>{prod.price} &#8381; / кг</span>
        </div>
        <button className='addButton' onClick={handleClick}>
          {isItemInCart ? (
            'убрать'
          ) : (
            <svg
              className={'addButton__svg'}
              width='17'
              height='17'
              viewBox='0 0 16 16'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M0.729492 7.94824H15.7295' strokeWidth='1.5' />
              <path d='M8.22949 15.4482L8.22949 0.448243' strokeWidth='1.5' />
            </svg>
          )}
        </button>
      </div>
    </li>
  );
};
