import { createSlice } from '@reduxjs/toolkit';

const prodSlice = createSlice({
    name: 'prods',
    initialState: {
        currentProd: null
    },
    reducers: {
        setCurrentProd: (state, action) => {
            state.currentProd = action.payload
        }
    }
});

export const { setCurrentProd } = prodSlice.actions;
export default prodSlice.reducer;