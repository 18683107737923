import "./style.css";
import TableFish from "../../components/tableFish/index";
import { useEffect, useState } from "react";
import axios from "axios";

export default function FreshwaterRiches() {
  const [fishMass, setFishMass] = useState();

  useEffect(() => {
    axios.get("data/freshwater-riches.json").then((response) => {
      console.log(response.data);
      setFishMass(response.data);
    });
  }, []);
  if (!fishMass) return null;
  return (
    <>
      <main className="page-riches">
        <h2 className="page-riches__title">Пресноводные богатства</h2>
        <TableFish fishMass={fishMass}></TableFish>
      </main>
    </>
  );
}
